/*------------------------------------------------------------------
[Main Stylesheet]

Project:    DISEE
Version:    1.1.0
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Table of contents]
    *. Invoice 1 / .invoice-1
    *. Invoice 2 / .invoice-2
    *. Invoice 3 / .invoice-3
    *. Invoice 4 / .invoice-2
    *. Invoice 5 / .invoice-5
    *. Invoice 6 / .invoice-7
    *. Invoice 5 / .invoice-7
    *. Invoice 6 / .invoice-8

-------------------------------------------------------------------*/
/** GLOBAL CLASSES **/

.order-summary-inv4 .table {
  color: #535353;
}

.invoice-content {
  font-family: 'Poppins', sans-serif;
  color: #535353;
  font-size: 14px;
}

.invoice-content a {
  text-decoration: none;
}

.invoice-content .img-fluid {
  max-width: 100% !important;
  height: auto;
}

.invoice-content .form-control:focus {
  box-shadow: none;
}

.invoice-content h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Poppins', sans-serif;
  color: #535353;
}


.invoice-4 .container{
  max-width: 1000px;
  margin: 0 auto;
}



.invoice-content .f-w-600 {
  font-weight: 500 !important;
}

.invoice-content .text-14 {
  font-size: 14px;
}

.invoice-content .invoice-table th:first-child,
.invoice-content .invoice-table td:first-child {
  text-align: left;
}

.invoice-content .color-white {
  color: #fff!important;
}

.invoice-content .inv-header-1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
}

.invoice-content .inv-header-2 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.invoice-content .inv-title-1 {
  font-weight: 500;
  font-size: 16px;
}

.invoice-content .invo-addr-1 {
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 23px;
}

.invoice-content .item-desc-1 {
  text-align: left;
}

.invoice-content .item-desc-1 span {
  display: block;
}

.invoice-content .item-desc-1 small {
  display: block;
}

.invoice-content .important-notes-list-1 {
  font-size: 14px !important;
  padding-left: 15px;
  margin-bottom: 15px;
}

.invoice-content .important-notes-list-1 li {
  margin-bottom: 5px;
}

.invoice-content .bank-transfer-list-1 {
  font-size: 13px !important;
  padding-left: 0px;
}

.invoice-content .important-notes {
  font-size: 12px !important;
}

.invoice-content .invoice-btn-section {
  text-align: center;
  margin-top: 27px;
}

.order-summary-inv4 table th{
  font-weight: 400;
}

.order-summary-inv4 .invoice-table tr {
  border: 1px solid #e9ecef;
}


/** Invoice 4 start **/
.invoice-4 {
  padding: 30px 0;
  background: #fff6f6;
}

.invoice-4 .mb-30{
  margin-bottom: 30px;
}

.invoice-4 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #535353;
}

.invoice-4 .invoice-inner {
  background: #fff;
}

.invoice-4 .item-desc-1 span {
  font-size: 14px;
  font-weight: 500;
  color: #535353;
}

.invoice-4 .invoice-titel{
  padding: 20px 50px 15px;
  position: relative;
  z-index: 0;
}

.invoice-4 .invoice-titel:before {
  content: "";
  width: 350px;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 0 30px 30px 0;
  background: #308b7b;
}

.invoice-4 .invoice-titel:after {
  content: "";
  width: 350px;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 30px 0 0 30px;
  background: #308b7b;
}

.invoice-4 .bank-transfer-list-1 li strong{
  font-weight: 500;
}

.invoice-4 .fw-bold {
  font-weight: 500!important;
}

.invoice-4 .item-desc-1 small {
  font-size: 13px;
  color: #535353;
}

.invoice-4 .invoice-top .logo img {
  height: 25px;
}

.invoice-4 .invoice-top {
  padding: 50px 50px 40px;
}

.invoice-4 .invoice-top .invoice h1 {
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 28px;
  color: #262525;
}

.invoice-4 .inv-title-1{
  color: #262525;
  font-weight: bold;
}

.invoice-4 .invoice-info {
  padding: 50px 50px 20px;
}

.invoice-4 .invoice-info p {
  margin-bottom: 0;
}

.invoice-4 .invoice-titel h3 {
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 16px;
  color: whitesmoke;
}

.invoice-4 .order-summary-inv4 {
  padding: 0 50px 50px;
}

.invoice-4 .order-summary-inv4 .table {
  margin-bottom: 0;
}

.invoice-4 .table td, .invoice-4 .table th {
  padding: 13px 20px;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  text-align: center;
}

.invoice-4 .table > :not(caption) > * > * {
  box-shadow: none;
}

.invoice-4 .table tr, .invoice-4 .table tr strong {
  font-size: 15px;
  text-transform: initial !important;
}

.invoice-4 .table>thead {
  vertical-align: bottom;
  font-weight: 500;
}

.invoice-4 .invoice-informeshon {
  padding: 0 50px 20px;
}

.invoice-4 .text-muted {
  color: #535353 !important;
}

.invoice-4 .invoice-informeshon p {
  font-size: 14px;
}

.invoice-4 .inv-title-1{
  margin-bottom: 7px;
}

.invoice-4 thead th {
  font-weight: 500;
}

.invoice-4 .invoice-contact {
  padding: 25px 50px;
  z-index: -1;
  background: #e9e9e9;
}

.invoice-4 .bank-transfer-list-1 {
  list-style: none;
  padding: 0;
  font-size: 14px !important;
}

.invoice-4 .invoice-contact a {
  margin-right: 20px;
  color: #535353;
  font-size: 14px;
}

.invoice-4 .mr-0{
  margin-right: 0;
}

@media (max-width: 992px) {
  .invoice-4 {
      padding: 30px 0;
  }
}

@media (max-width: 574px){

.invoice-4 .invoice-info .company-iban {
  text-align: left;
}
}

@media (min-width: 722px){

  .invoice-4 .invoice-info .company-iban {
    text-align: right;
  }

  .invoice-4 .invoice-info .iban {
    margin-left:80px;
    margin-right:12px
  }

  .invoice-4 .invoice-info .rek {
    margin-left:25px;
    margin-right:51px
  }

  .invoice-4 .invoice-info .plaat {
    margin-left:92px;
    margin-right:84px
  }

  .invoice-4 .invoice-info .thk-num {
    margin-left:6px;
    margin-right:107px
  }

}

@media (max-width: 574px){

  .invoice-4 .invoice-info .company-iban {
    text-align: left;
  }
  
  }

@media (min-width: 320px)  and (max-width: 768px){
    .invoice-4 .invoice-info .company-iban {
      /* text-align: right !important; */
    }

    .invoice-4 .invoice-info .company-iban .iban {
      margin-left: 100px;
      margin-right: 2px;
    }

    .invoice-4 .invoice-info .company-iban .rek {
      margin-left: 9px;
      margin-right: 2px;
    }

    .invoice-4 .invoice-info .company-iban .plaat {
      margin-left: 73px;
      margin-right: 3px;
    }

    .invoice-4 .invoice-info .company-iban .thk-num {
      margin-left: 61px;
      margin-right: 2px;
    }
}

@media (max-width: 768px) {
  .invoice-4 .invoice-top {
      padding: 30px 30px 25px;
  }

  .invoice-4 .order-summary-inv4 {
      padding: 0 30px 30px;
  }

  .invoice-4 .invoice-informeshon {
      padding: 0 30px;
  }

  .invoice-4 .invoice-contact {
      padding: 20px 30px;
  }

  .invoice-4 .invoice-top .logo img {
      height: 20px;
      margin-bottom: 5px;
  }

  .invoice-4 .invoice-top .invoice h1 {
      font-size: 24px;
  }

  .invoice-4 .invoice-titel {
      background: #308B7B;
      padding: 20px 68px 15px;
  }


  .invoice-4 .invoice-titel:before {
      display: none;
  }

  .invoice-4 .invoice-titel:after {
      display: none;
  }

  .invoice-4 .invoice-titel h3 {
      font-size: 16px;
  }

  .invoice-4 .invoice-info {
      padding: 30px 30px 0;
  }
}

@media (max-width: 580px) {
  .invoice-4 .invoice-top .invoice{
      float: left;
  }

  .invoice-4 .invoice-content .important-notes-list-1 {
      margin-bottom: 25px;
  }

  .invoice-4 .text-end {
      text-align: left !important;
  }

  .invoice-4 .invoice-name {
      margin-top: 20px;
      margin-bottom: 30px;
  }

  .invoice-4 .d-none-580{
      display: none!important;
  }
}
/** Invoice 4 end **/

/** Invoice 5 start **/
.invoice-5 {
  padding: 30px 0;
  background: #fff6f6;
}

.invoice-5 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #262525;
}

.invoice-5 .invoice-info {
  background: #fff;
  position: relative;
  padding: 15px;
  z-index: 0;
}

.invoice-5 .name.mb-10{
  margin-bottom: 10px;
}

.invoice-5 .mb-30{
  margin-bottom: 30px;
}



.invoice-5 .invoice-contant{
  background: #fff;
}

.invoice-5 .invoice-contact-us ul{
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

.invoice-5 .logo img{
  height: 30px;
  margin-top: 8px;
}

.invoice-5 .invoice-headar{
  padding: 30px;
  border-bottom: solid 1px #ebeaea;
}

.invoice-5 .invoice-contact-us ul li{
  font-size: 14px;
  line-height: 25px;
}

.invoice-5 .invoice-contact-us ul li i{
  width: 20px;
}

.invoice-5 .invoice-contact-us ul li a{
  color: #535353;
}

.invoice-5 .inv-title-1 {
  color: #ff1f1f;
  font-weight: 400;
  margin-bottom: 5px;
}

.invoice-5 .name {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 500;
  text-transform: uppercase;
  color: #262525;
}

.invoice-5 .table-outer {
  overflow-y: hidden;
  overflow-x: auto;
}

.invoice-5 .default-table thead th {
  position: relative;
  padding: 20px 30px;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  white-space: nowrap;
  color: #262525;
}

.invoice-5 .default-table tbody tr {
  position: relative;
  border-bottom: 1px solid #ECEDF2;
}

.invoice-5 .default-table tr td {
  position: relative;
  padding: 21px 30px;
  font-size: 14px;
  color: #535353;
  font-weight: 400;
}

.invoice-5 .default-table tr td strong{
  font-weight: 500;
}

.invoice-5 .default-table {
  position: relative;
  background: #ffffff;
  border: 0;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  min-width: 550px;
}

.invoice-5 .default-table thead {
  background: #F5F7FC;
  border-radius: 8px;
  color: #ffffff;
}

.invoice-5 .payment-method ul {
  list-style: none;
  padding: 0;
}

.invoice-5 .payment-method ul li strong {
  font-weight: 500;
}

.invoice-5 .invoice-top{
  padding: 30px 30px 0;
  border-bottom: solid 1px #ebeaea;
}

.invoice-5 .order-summary{
  padding: 30px;
  border-bottom: solid 1px #ebeaea;
}

.invoice-5 .invoice-bottom{
  padding: 30px 30px 0;
}

.invoice-5 .invoice-bottom .inv-title-1{
  margin-bottom: 7px;
  color: #262525;
}

/** MEDIA **/
@media (max-width: 992px) {
  .invoice-5 {
      padding: 30px 0;
  }

  .invoice-5 .order-summary .default-table thead th {
      padding: 12px 20px;
  }

  .invoice-5 .order-summary .default-table tr td {
      padding: 12px 20px;
  }
}

@media (max-width: 768px) {
  .invoice-5 .invoice-top {
      padding: 30px 30px 0;
  }

  .invoice-5 .invoice-5 .order-summary {
      padding: 30px;
  }

  .invoice-5 .invoice-contact-us{
      display: none;
  }

  .invoice-2 .invoice-id .info {
      margin: 0 auto 0 0;
      padding: 0;
  }

  .invoice-5 .invoice-bottom {
      padding: 30px 30px 0;
  }

  .invoice-5 .invoice-headar {
      padding: 30px;
  }

  .invoice-5 .invoice-info {
      background: #f5f7fc;
      padding: 0;
  }


  .invoice-5 .default-table tr td {
      padding: 15px 20px;
  }

  .invoice-5 .default-table thead th {
      padding: 15px 20px;
  }

  .invoice-5 .order-summary .default-table thead th {
      padding: 10px 12px;
  }

  .invoice-5 .order-summary .default-table tr td {
      padding: 10px 12px;
  }
}

@media (max-width: 580px){
  .invoice-5 .invoice-contact-us {
      max-width: 100%;
      margin: 0;
  }

  .invoice-5 .invoice-number-inner {
      max-width: 100%;
      margin: 0;
  }

  .invoice-5 .payment-method {
      max-width: 100%;
      margin: 0 0 30px;
  }

  .invoice-5 .logo img {
      height: 25px;
      margin: 0 0 10px;
  }
}
/** Invoice 5 end **/

/** Invoice 6 start **/
.invoice-6 {
  padding: 30px 0;
  background: #fff6f6;
}

.invoice-6 .mb-30{
  margin-bottom: 30px;
}

.invoice-6 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #262525;
}

.invoice-6 .invoice-info {
  background: #f3f4f7;
  position: relative;
  padding: 50px;
  z-index: 0;
}

.invoice-6 .invoice-info:before {
  content: "";
  width: 100%;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: #d7e3ff;
}

.invoice-6 .invoice-contant{
  background: #fff;
}

.invoice-6 .invoice-contact-us{
  max-width: 230px;
  margin-left: auto;
}

.invoice-6 .invoice-contact-us ul{
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

.invoice-6 .logo img{
  height: 30px;
  margin-top: 42px;
}

.invoice-6 .invoice-headar{
  padding: 0 50px 40px;
}

.invoice-6 .invoice-contact-us h1{
  font-size: 20px;
  margin-bottom: 15px;
  color: #262525;
}

.invoice-6 .invoice-contact-us ul li{
  font-size: 14px;
  line-height: 25px;
  color: #535353;
}

.invoice-6 .invoice-contact-us ul li i{
  width: 20px;
  color: #535353;
}

.invoice-6 .invoice-contact-us ul li a{
  color: #535353;
}

.invoice-6 .inv-title-1 {
  color: #005ce7;
  margin-bottom: 5px;
}

.invoice-6 .name {
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #262525;
  font-weight: 500;
}

.invoice-6 .name.mb-10{
  margin-bottom: 10px;
}

.invoice-6 .invoice-number-inner{
  max-width: 230px;
  margin-left: auto;
}

.invoice-6 .invoice-name{
  font-weight: 600;
  font-size: 30px;
}

.invoice-6 .table-outer {
  overflow-y: hidden;
  overflow-x: auto;
}

.invoice-6 .default-table thead th {
  position: relative;
  padding: 20px 30px;
  font-size: 15px;
  color: #005ce7;
  font-weight: 500;
  line-height: 30px;
  white-space: nowrap;
}

.invoice-6 .default-table tbody tr {
  position: relative;
  border-bottom: 1px solid #ECEDF2;
}

.invoice-6 .default-table tr td {
  position: relative;
  padding: 21px 30px;
  font-size: 14px;
  color: #535353;
  font-weight: 400;
}

.invoice-6 .default-table tr td strong{
  font-weight: 500;
}

.invoice-6 .default-table {
  position: relative;
  background: #ffffff;
  border: 0;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  min-width: 550px;
}

.invoice-6 .default-table thead {
  background: #F5F7FC;
  border-radius: 8px;
  color: #ffffff;
}

.invoice-6 .payment-method ul {
  list-style: none;
  padding: 0;
}

.invoice-6 .payment-method ul li strong {
  font-weight: 500;
}

.invoice-6 .invoice-top{
  padding: 50px 50px 20px;
}

.invoice-6 .order-summary{
  padding: 0 50px 50px;
}

.invoice-6 .invoice-bottom{
  padding: 0 50px 20px;
}

.invoice-6 .invoice-bottom .inv-title-1{
  margin-bottom: 7px;
}

.invoice-6 .payment-method{
  max-width: 230px;
  margin-left: auto;
}

/** MEDIA **/
@media (max-width: 992px) {
  .invoice-6 {
      padding: 30px 0;
  }

  .invoice-6 .order-summary .default-table thead th {
      padding: 12px 20px;
  }

  .invoice-6 .order-summary .default-table tr td {
      padding: 12px 20px;
  }
}

@media (max-width: 768px) {
  .invoice-6 .invoice-top {
      padding: 30px 30px 0;
  }

  .invoice-6 .order-summary {
      padding: 0 30px 30px;
  }

  .invoice-2 .invoice-id .info {
      margin: 0 auto 0 0;
      padding: 0;
  }

  .invoice-6 .invoice-bottom {
      padding: 0 30px 0;
  }

  .invoice-6 .invoice-headar {
      padding: 30px;
  }

  .invoice-6 .invoice-info {
      background: #f5f7fc;
      padding: 0;
  }


  .invoice-6 .default-table tr td {
      padding: 15px 20px;
  }

  .invoice-6 .default-table thead th {
      padding: 15px 20px;
  }

  .invoice-6 .order-summary .default-table thead th {
      padding: 10px 12px;
  }

  .invoice-6 .order-summary .default-table tr td {
      padding: 10px 12px;
  }
}

@media (max-width: 580px){
  .invoice-6 .invoice-contact-us {
      max-width: 100%;
      margin: 0;
  }

  .invoice-6 .invoice-number-inner {
      max-width: 100%;
      margin: 0;
  }

  .invoice-6 .payment-method {
      max-width: 100%;
      margin: 0 0 30px;
  }

  .invoice-6 .logo img {
      height: 25px;
      margin: 0 0 10px;
  }

  .invoice-6 .invoice-name {
      font-size: 24px;
  }
}
/** Invoice 6 end **/

/** Invoice 7 start **/
.invoice-7 {
  padding: 30px 0;
  background: #fff6f6;
}

.invoice-7 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #535353;
}

.invoice-7 .mb-30{
  margin-bottom: 30px;
}

.invoice-7 .invoice-inner {
  background: #fff;
  position: relative;
  z-index: 0;
}



.invoice-7 .item-desc-1 span {
  font-size: 14px;
  font-weight: 500;
  color: #535353;
}

.invoice-7 .bank-transfer-list-1 li strong{
  font-weight: 500;
}

.invoice-7 .fw-bold {
  font-weight: 500!important;
}

.invoice-7 .item-desc-1 small {
  font-size: 13px;
  color: #535353;
}

.invoice-7 .invoice-top .logo img {
  height: 25px;
}

.invoice-7 .invoice-top {
  padding: 50px 100px;
}

.invoice-7 .invoice-top .invoice h1 {
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 28px;
  color: #336ff6;
}

.invoice-7 .invoice-info {
  padding: 50px 100px 20px;
  background: #f7f7f7;
}

.invoice-7 .invoice-info p {
  margin-bottom: 0;
}

.invoice-7 .order-summary {
  padding: 50px 100px;
}

.invoice-7 .order-summary .table {
  margin-bottom: 0;
}

.invoice-7 .table td, .invoice-7 .table th {
  padding: 10px 20px;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  text-align: center;
}

.invoice-7 .table > :not(caption) > * > * {
  box-shadow: none;
}

.invoice-7 .table tr, .invoice-7 .table tr strong {
  font-size: 14px;
}

.invoice-7 .bg-active {
  text-transform: uppercase;
  background: #f7f7f7;
  color: #535353!important;
}

.invoice-7 .table>thead {
  vertical-align: bottom;
  font-weight: 500;
}

.invoice-7 .invoice-informeshon {
  padding: 50px 100px 20px;
  background: #f7f7f7;
}

.invoice-7 .text-muted {
  color: #535353 !important;
}

.invoice-7 .invoice-informeshon p {
  font-size: 14px;
}

.invoice-7 .inv-title-1{
  margin-bottom: 7px;
  color: #336ff6;
  text-transform: uppercase;
}

.invoice-7 thead th {
  font-weight: 500;
}

.invoice-7 .invoice-contact {
  padding: 30px 100px;
  z-index: -1;
}

.invoice-7 .bank-transfer-list-1 {
  list-style: none;
  padding: 0;
  font-size: 14px !important;
}

.invoice-7 .invoice-contact a {
  margin-right: 20px;
  color: #535353;
  font-size: 14px;
}

.invoice-7 .invoice-contact a i{
  color: #336ff6;
}

.invoice-7 .mr-0{
  margin-right: 0;
}

@media (max-width: 992px) {
  .invoice-7 {
      padding: 30px 0;
  }

  .invoice-7 .invoice-top {
      padding: 50px;
  }

  .invoice-7 .invoice-info {
      padding: 50px 50px 20px;
  }

  .invoice-7 .order-summary {
      padding: 50px;
  }

  .invoice-7 .invoice-informeshon {
      padding: 50px 50px 20px;
  }

  .invoice-7 .invoice-inner:after {
      display: none;
  }

  .invoice-7 .invoice-inner:before {
      display: none;
  }

  .invoice-7 .invoice-contact {
      padding: 20px 50px;
  }

  .invoice-7 .d-none-992{
      display: none;
  }
}

@media (max-width: 768px) {
  .invoice-7 .invoice-top {
      padding: 30px 30px 25px;
  }

  .invoice-7 .order-summary {
      padding: 30px;
  }

  .invoice-7 .invoice-informeshon {
      padding: 30px 30px 0;
  }

  .invoice-7 .invoice-contact {
      padding: 20px 30px;
  }

  .invoice-7 .invoice-top .logo img {
      height: 20px;
      margin-bottom: 5px;
  }

  .invoice-7 .invoice-top .invoice h1 {
      font-size: 24px;
  }

  .invoice-7 .invoice-info {
      padding: 30px 30px 0;
  }
}

@media (max-width: 580px) {
  .invoice-7 .invoice-top .invoice{
      float: left;
  }

  .invoice-7 .invoice-content .important-notes-list-1 {
      margin-bottom: 25px;
  }

  .invoice-7 .text-end {
      text-align: left !important;
  }

  .invoice-7 .invoice-name {
      margin-top: 20px;
      margin-bottom: 30px;
  }

  .invoice-7 .d-none-580{
      display: none!important;
  }
}
/** Invoice 7 end **/

/** Invoice 8 start **/
.invoice-8 {
  padding: 30px 0;
  background: #fff;
}

.invoice-8 .invoice-info {
  position: relative;
  z-index: 0;
  padding: 70px;
  background: #c5f0ff;
  border-radius: 0px;
}


.invoice-8 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #262525;
}

.invoice-8 .invoice-contact-us{
  max-width: 230px;
  margin-left: auto;
}

.invoice-8 .invoice-contact-us ul{
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

.invoice-8 .logo img{
  height: 30px;
  margin-top: 40px;
}

.invoice-8 .invoice-headar{
  padding: 40px;
  margin-bottom: 30px;
}

.invoice-8 .invoice-contact-us h1{
  font-size: 20px;
  margin-bottom: 10px;
}

.invoice-8 .invoice-contact-us ul li{
  font-size: 14px;
  line-height: 25px;
}

.invoice-8 .invoice-contact-us ul li i{
  width: 20px;
}

.invoice-8 .invoice-contact-us ul li a{
  color: #535353;
}

.invoice-8 .invoice-contact-us ul li i{
  color: #11bbb0;
}

.invoice-8 .inv-title-1 {
  color: #11bbb0;
  margin-bottom: 5px;
}

.invoice-8 .name {
  font-size: 18px;
  margin-bottom: 5px;
  color: #262525;
  font-weight: 500;
  text-transform: uppercase;
}

.invoice-8 .mb-30{
  margin-bottom: 30px;
}

.invoice-8 .mb-10{
  margin-bottom: 10px;
}

.invoice-8 .invoice-number-inner{
  max-width: 230px;
  margin-left: auto;
}

.invoice-8 .invoice-name{
  font-weight: 600;
  font-size: 30px;
}

.invoice-8 .table-outer {
  overflow-y: hidden;
  overflow-x: auto;
}

.invoice-8 .default-table thead th {
  position: relative;
  padding: 20px 30px;
  font-size: 15px;
  color: #11bbb0;
  font-weight: 500;
  line-height: 30px;
  white-space: nowrap;
}

.invoice-8 .default-table tbody tr {
  position: relative;
  border-bottom: 1px solid #ECEDF2;
}

.invoice-8 .default-table tr td {
  position: relative;
  padding: 21px 30px;
  font-size: 14px;
  color: #535353;
  font-weight: 400;
}

.invoice-8 .default-table tr td strong{
  font-weight: 500;
}

.invoice-8 .default-table {
  position: relative;
  background: transparent;
  border: 0;
  overflow: hidden;
  width: 100%;
  min-width: 550px;
  border-radius: 20px;
}

.invoice-8 .payment-method ul {
  list-style: none;
  padding: 0;
}

.invoice-8 .payment-method ul li strong {
  font-weight: 500;
}

.invoice-8 .invoice-top{
  padding: 40px 40px 10px;
  margin-bottom: 30px;
}

.invoice-8 .invoice-bottom{
  padding: 40px 40px 10px;
}

.invoice-8 .invoice-center{
  margin-bottom: 30px;
}

.invoice-8 .border-shadow-bg{
  background: #fff;
}

.invoice-8 .payment-method{
  max-width: 230px;
  margin-left: auto;
}

/** MEDIA **/
@media (max-width: 992px) {
  .invoice-8 {
      padding: 30px 0;
  }

  .invoice-8 .invoice-info {
      padding: 50px;
  }
}

@media (max-width: 768px) {
  .invoice-8 .invoice-top {
      padding: 30px 30px 0;
      margin-bottom: 0;
  }

  .invoice-8 .default-table {
      border-radius: 0;
  }

  .invoice-8 {
      background: #fff6f6;
  }

  .invoice-8 .invoice-info:after{
      display: none;
  }

  .invoice-8 .order-summary {
      padding: 30px;
      margin-bottom: 0;
  }

  .invoice-2 .invoice-id .info {
      margin: 0 auto 0 0;
      padding: 0;
  }

  .invoice-8 .invoice-bottom {
      padding: 30px 30px 0;
  }

  .invoice-8 .invoice-headar {
      padding: 30px;
      margin-bottom: 0;
  }

  .invoice-8 .invoice-info {
      padding: 0px;
  }

  .invoice-8 .default-table tr td {
      padding: 10px 12px;
  }

  .invoice-8 .default-table thead th {
      padding: 10px 12px;
  }

  .invoice-8 .border-shadow-bg {
      border-radius: 0;
      border-bottom: solid 1px #e5e5e5;
  }

  .invoice-8 .border-shadow-bg.bsb2{
      border-bottom: none;
  }

  .invoice-8 .invoice-center {
      margin-bottom: 0;
  }
}

@media (max-width: 580px){
  .invoice-8 .invoice-contact-us {
      max-width: 100%;
      margin: 0;
  }

  .invoice-8 .invoice-number-inner {
      max-width: 100%;
      margin: 0;
  }

  .invoice-8 .payment-method {
      max-width: 100%;
      margin: 0 0 30px;
  }

  .invoice-8 .logo img {
      height: 25px;
      margin: 0 0 10px;
  }
}
/** Invoice 8 end **/









